
import Footer from '../../components/Footer';
import Header from '../../components/Header';  
import About from './About';
import Banner from './Banner';
import OurMission from './OurMission';
import Startups from './Startups'; 
import Support from './Support';
import SupportedStartups from './SupportedStartups';
import HomeBlogs from './HomeBlogs';
function Home() { 
	return( 
	<>
  <div id="body">
    <Header />

    
    <main class="inner-page" id="main-container">
      <Banner/>
      <About/>
      <OurMission/>
      <Startups/>
      <SupportedStartups/>
      {/* <Support/> */}
      <HomeBlogs/>
    </main>
    <Footer/>
    </div>
    
        
  </> 
  ); 
  } 
  export default Home;