import React from 'react';

function  Footer () {
  return (
  <>
  
  <footer class="ws-footer">
		<section>
			<div class="container-fluid">
				<div class="row border-footer">
				<div class="col-md-6 br-r f-inner-div">
				<img src="../assets/images/logo-white.svg" class="footer-logo" alt="logo"/>
					<p>Pixalive Platforms is the pioneering division of the Pixalive Group, specializing in the development and provision of a diverse portfolio of social media applications, including those tailored for social networking and business/commercial sectors, with additional projects in various stages of development. </p>
					<p></p>
				</div>  
				<div class="col-md-3 br-r f-inner-div">
					<div class="footer-link">
						{/* <a class="pad-footer" href="#">About Us</a> */}
						<a class="pad-footer" href="/Products">Products</a>
						<a class="pad-footer" href="/Investors">Investors</a> 
						<a class="pad-footer" href="/Blog">Blogs</a> 
						<a class="pad-footer" href="/ContactUs">Contact Us</a> 
					</div>
				</div>
				<div class="col-md-3 f-inner-div social-media">
					<div class="align-left">
							<span>FOLLOW US ON</span>
							 <a target="_blank" href="https://twitter.com/pixaliveapp"><img src="../assets/images/twitter.png"  alt="Twitter Icon"/></a>
							<a target="_blank" href="https://www.linkedin.com/company/pixalive/?trk=profile-position"><img src="../assets/images/linkedin.png" alt="Linkedin Icon"/></a>
							<a target="_blank" href="https://www.youtube.com/@PixaliveApp"><img src="../assets/images/youtube.png" alt="Youtube Icon"/></a>
						 </div>
				</div>
				</div>
				<div class="row">
					<div class="bottom-footer">
						<span><span>&#169;</span> Copyright 2023. All Rights Reserved.</span>
                      	 
					</div>
				</div>
				
			</div>
		</section>
	</footer>

  </>
);
}

export default Footer;