
import Header from '../../components/Header'; 
import Footer from '../../components/Footer'; 

function AboutUs() { 
	return( 
	<>
    <div id="body">
    <Header />
    <main class="inner-page" id="main-container">
    <section class="inner-page-banner">
        <div class="card inner-banner-image">
			<div class="card-img-overlay">
				<div class="breadcrumb">
					<a href="/">Home</a> <span class="seperator">/</span>
					<a href="/AboutUs">About Us</a> 
				</div>
				<h1 class="card-title">About Us</h1>
			</div>
		</div> 
	</section>

    <section class="pixalive-intro pixalive-intro-page">
		<div class="container-fluid">
			<div class="row">
                <div class="col-md-5" >
                    <h1 class="gradient-text h1-gt">ABOUT US </h1>
                    <h2 class="big-heading mt-lg-5 mt-2"> Pixalive Platforms </h2>
                </div>
                <div class="col-md-7 pt-lg-5 mb-5 d-flex-data pl-lg-5 pr-lg-5">
                    <p>Pixalive Platforms is the pioneering division of the Pixalive Group, specializing in the development and provision of a diverse portfolio of social media applications, including those tailored for social networking and business/commercial sectors, with additional projects in various stages of development. </p>
                    <p class="mt-2 mt-lg-2 mb-5">These applications are designed with the primary objective of enlightening, benefiting, and providing practical solutions to users, addressing the diverse challenges and needs of the modern world.</p>
                    
                </div>
			</div>
			{/* <div class="pt-5 mt-lg-4 home-card-div">
                <div class="home-card">
                    <img src="../assets/images/home-card-icon1.webp" alt="earth icon" />
                    <div class="card-data">
                    <p>Team Strength</p>
                    <h5>40+</h5>
                    </div>
                </div>
                <div class="home-card">
                    <img src="../assets/images/home-card-icon2.webp" alt="team icon" />
                    <div class="card-data">
                    <p>Trees Planted</p>
                    <h5>1.5K+</h5>
                    </div>
                </div>
                <div class="home-card">
                    <img src="../assets/images/home-card-icon3.webp" alt="graph icon" />
                    <div class="card-data">
                    <p>Projects Completed</p>
                    <h5>27+</h5>
                    </div>
                </div>
			</div> */}
		</div>
	</section>

    <section class="vision-mission-section">
		<div class="container-fluid">
			<div class="row">
				<div class="col-md-4">
					<div class="card pix-card mr-lg-5 ml-lg-2">
						<div class="card-body">
							{/* <img src="images/vision-icon.webp" alt="vision img"/> */}
							<h2 class="card-title">Our Vision</h2>
							<p class="card-text">Our vision is to become India's foremost social media networking platform across diverse verticals, connecting with a billion people. We also aim to establish our own data centre for future data localization</p>
						</div>
					</div>
				</div>
				<div class="col-md-4">
					<div class="card pix-card ml-lg-5 mr-lg-2">
						<div class="card-body">
							{/* <img src="images/mission-icon.webp" alt="mission icon"/> */}
							<h2 class="card-title">Our Mission</h2>
							<p class="card-text mr-lg-3">We aim to develop social media applications that provide solutions to the challenges people face in the modern world. We also seek to encourage and empower the Indian community. Our ultimate goal is to elevate our company to the status of a 'Unicorn' while maintaining the highest standards of privacy.</p> 
						</div>
					</div>
				</div>
                <div class="col-md-4">
					<div class="card pix-card ml-lg-5 mr-lg-2">
						<div class="card-body">
							{/* <img src="images/mission-icon.webp" alt="mission icon"/> */}
							<h2 class="card-title">MANTRA</h2>
							<p class="card-text mr-lg-3">“Our Nation; Our Social Media”</p> 
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>

    <section class="people-culture mt-lg-5 pt-4">
        <div class="container-fluid">
            <div class="row learn-n-develop pt-lg-4 mt-4 pb-5">
                <div class="col-md-6 text-prt">
                    <h2>Pixalive Platforms <br/>Process</h2>
                    <p>At Pixalive Platforms, we have a deep understanding of the ever-evolving landscape of social media and the dynamic nature of contemporary issues faced by individuals and communities. As a result, we have curated a suite of social products that not only connect people but also enhance their lives.</p>
                    <p>Our platforms offer features and tools that empower users to stay informed, engaged, and connected with their peers and the world at large. Whether it's fostering personal connections, staying up to date with the latest trends, or finding solutions to real-world problems, Pixalive Platforms is committed to providing valuable and enriching experiences to our users.</p>
                    <p>We firmly believe in the power of technology to create positive change and aspire to be at the forefront of this transformative journey, making a meaningful impact in the lives of those we serve.</p>
                </div>
                <div class="col-md-6">
                    <div class="learn-slider">
                        <img src="../assets/images/office/0.jpg" alt="group img" />
                        <img src="../assets/images/office/1.jpg" alt="group img" />
                        <img src="../assets/images/office/2.jpg" alt="group img" />
                        <img src="../assets/images/office/3.jpg" alt="group img" />
                        <img src="../assets/images/office/5.jpg" alt="group img" />
                        <img src="../assets/images/office/6.jpg" alt="group img" />
                        <img src="../assets/images/office/7.jpg" alt="group img" />
                        <img src="../assets/images/office/8.jpg" alt="group img" />
                        <img src="../assets/images/office/9.jpg" alt="group img" />
                    </div> 
                </div>
            </div> 
            
        </div>
    </section>

    <section class="leadership2" id="leaders">
  <div class="container-fluid">
    <div class="row text-center">
      <h6 class="gradient-text m-auto">THE TEAM</h6>
      <h2 class="big-heading2"> Platform Leadership </h2>
    </div>
    <div class="row mt-5 pt-lg-4 leadership-space">
      <div class="card">
        <img src="../assets/images/team/rajasekar.jpg" alt="Pixalive" />
        <div class="card-footer">
          <div class="name-div">
            <h3>Mr. Rajasekar Sundaresan</h3>
          <span>CEO, Pixalive Platforms</span>
          </div>
        </div>
      </div>
      <div class="card">
        <img src="../assets/images/team/3.jpg" alt="Pixalive" />
        <div class="card-footer">
          <div class="name-div">
            <h3>Mr. Nitesh Jain</h3>
          <span>Director, Pixalive Platforms</span>
          </div>
        </div>
      </div>
      <div class="card">
        <img src="../assets/images/team/4.jpg" alt="Pixalive" />
        <div class="card-footer">
          <div class="name-div">
            <h3>Mr. Abhishek Jain</h3>
          <span>Director, Pixalive Platforms</span>
          </div>
        </div>
      </div>
      <div class="card">
        <img src="../assets/images/team/2.jpg" alt="Pixalive" />
        <div class="card-footer">
          <div class="name-div">
            <h3>Mr. Bharath Kumar Jain</h3>
          <span>Legal Advisor to CEO</span>
          </div>
        </div>
      </div>
      <div class="card">
        <img src="../assets/images/team/vignesh.jpg" alt="Pixalive" />
        <div class="card-footer">
          <div class="name-div">
            <h3>Mr. Vignesh Dhanasekaran</h3>
          <span>COO, Pixalive Platforms</span>
          </div>
        </div>
      </div>
      <div class="card">
        <img src="../assets/images/team/sathish.jpg" alt="Pixalive" />
        <div class="card-footer">
          <div class="name-div">
            <h3>Mr. Sathish Kumar</h3>
          <span>Technical Advisor to CEO</span>
          </div>
        </div>
      </div>
      <div class="card">
        <img src="../assets/images/team/murali.jpg" alt="Pixalive" />
        <div class="card-footer">
          <div class="name-div">
            <h3>Mr. Murali Sundaresan</h3>
          <span>Director, Pixalive Platforms</span>
          </div>
        </div>
      </div> 
      <div class="card">
        <img src="../assets/images/team/viji.jpg" alt="Pixalive" />
        <div class="card-footer">
          <div class="name-div">
            <h3>Mr. Viji Vaithi</h3>
          <span>Group UI/UX Design Lead</span>
          </div>
        </div>
      </div>
      
    
    </div>
  </div>
</section>




      
    <Footer/>


    </main>
    </div> 
    </> 
    ); 
  } 
  export default AboutUs;