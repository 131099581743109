
import Header from '../../components/Header'; 
import Footer from '../../components/Footer';

function ContactUs() { 
	return( 
	<>
    
    <div id="body">
      <Header />
      <main class="inner-page" id="main-container">
         <section class="inner-page-banner">
         <div class="card inner-banner-image">
               <div class="card-img-overlay">
                  <div class="breadcrumb">
                     <a href="/">Home</a> <span class="seperator">/</span>
                     <a href="/ContactUs">Contact Us</a> 
                  </div>
                  <h1 class="card-title">Contact Us</h1>
               </div>
            </div> 
         </section>
 

    <section class="contacts pt-125 pb-40">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 mb-30">
                        <div class="row">
                            <div class="mb-30">
                                <div class="theme">
                                <h1 class="gradient-text h1-gt">CONTACT US </h1>
                                    <h2 class="theme__big--title fw-700 mb-25">Join us through all Social platforms to get updates</h2>
                                </div> 
                            </div>
                            <div class="col-lg-6 col-md-6 mb-40">
                                <div class="contacts__address">
                                    <i class="fal fa-envelope-open"></i>
                                    <h5 class="primary-color">Email Address</h5>
                                    <h4>contact@pixalive.me</h4>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 mb-40">
                                <div class="contacts__address">
                                    <i class="fal fa-phone"></i>
                                    <h5 class="primary-color">Phone Number</h5>
                                    <h4>+91 87785 84566</h4>
                                </div>
                            </div> 
                            <div class="col-lg-12 col-md-12 mb-40">
                                <div class="contacts__address">
                                    <i class="fal fa-map-marked-alt"></i>
                                    <h5 class="primary-color">Office Location</h5>
                                    <h4>Third floor, No 35/2 Konappana Agrahara, Hosur Rd, 2nd Phase, Electronic City, Karnataka 560100</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                <div class="col-md-6 form-wrapper  grey-bg">
                        <div class="row align-items-center">
                            <div class="col-sm-12 text-center mb-40">
                                <div class="theme">
                                    <h2 class="black-color mb-15">Send Us a Message</h2>
                                    <h5 class="fw-500 primary-color">We have a expert teams don’t he site to contact us</h5>
                                </div>
                            </div>
                        </div>
                        <div class="contact-form">
                            <form id="contact-form" action="#">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-box mb-30">
                                            <input type="text" name="name" placeholder="Your Name"/>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-box mb-30">
                                            <input type="text" name="email" placeholder="Your Email"/>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-box mb-30">
                                            <input type="text" name="phone" placeholder="Your Mobile No"/>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-box mb-30">
                                            <input type="text" name="phone" placeholder="Your Subject"/>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-box message-icon mb-30">
                                            <textarea name="message" id="message" cols="30" rows="10" placeholder="Your Message"></textarea>
                                        </div>
                                        <div class="contact-btn text-center">
                                            <button class="border-link" type="submit">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form> 
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>

        <section class="contact-form-area">
            <div class="container">
                <div class="row">
                    
                    <div class="col-md-12"> 
                <div id="google_map" class="google_map">
                    <div class="map_container">
                        <div id="map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15559.671290268201!2d77.6706383!3d12.8485841!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae6d95c77b9285%3A0x580316a77880fa5!2sPixalive!5e0!3m2!1sen!2sin!4v1704900918681!5m2!1sen!2sin" width="100%" height="500"></iframe>
                        </div>
                    </div>
                </div>
            </div>
                   
                </div>
            </div>
        </section> 

                
        <Footer/>
      </main>
   </div> 

    </> 
    ); 
  } 
  export default ContactUs;