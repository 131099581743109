
function OurMission() { 
	return( 
	<>

<section class="scaling mt-lg-5">
					<div class="container-fluid">
						<div class="row text-center" data-aos="fade-up">
							<h6 class="gradient-text">Application Categories</h6>
							<h2 class="big-heading2">
							We have applications in the <br class="display-lg" /> following categories
							</h2>
							<div class="scaling-new-section mt-lg-5 pt-4">
								<div class="about-slider">
									<div class="card about-card">
										<img src="../assets/images/a1.jpg" alt="Home Textiles related img" />
										<a target="_blank" href="/AboutUs">
										    <div class="card-img-overlay text-white d-flex flex-column">
    											<div>
    												<h2>Entertainment</h2>
    												<span class="date-span">We have already launched a social entertainment application with a combined user base of 500,000. </span>
    												<span class="d-link border-link">View More </span>
    											</div>
    										</div>
										</a>
									</div>
									<div class="card about-card">
										<img src="../assets/images/a2.jpg" alt="Pipes & Steel img" />
										<a target="_blank" href="/AboutUs">
    										<div class="card-img-overlay text-white d-flex flex-column">
    											<div>
    												<h2>Business</h2>
    												<span class="date-span">Our business-oriented social application is currently in the testing stage and is soon to be launched.</span>
        											<span class="d-link border-link">View More</span>
    											</div>
    										</div>
										</a>
									</div>
									<div class="card about-card">
										<img src="../assets/images/a3.jpg" alt="Infrastructure & Energy img" />
										<a target="_blank" href="/AboutUs">
    										<div class="card-img-overlay text-white d-flex flex-column">
    											<div>
    												<h2>Other Product</h2>
    												<span class="date-span">We also have several other applications in the pipeline, at various stages, including Proof of Concept (POC)...</span>
    												<span class="d-link border-link">View More</span>
    											</div>
    										</div>
										</a>
									</div> 
									
 
								</div>
								<ul class="about-controls">
									<li class="about-prev"><img src="../assets/images/left-arrow-black.webp" alt="arrow"/></li>
									<li class="about-next"><img src="../assets/images/right-arrow-black.webp" alt="arrow"/></li>
								</ul>
							</div>
						</div>
					</div>
				</section>
  </> 
  ); 
  } 
  export default OurMission;