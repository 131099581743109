
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./pages/Home/Home";
import Banner from "./pages/Home/Banner";
import About from "./pages/Home/About";
import Startups from "./pages/Home/Startups";
import OurMission from "./pages/Home/OurMission";
import SupportedStartups from "./pages/Home/SupportedStartups";
import Support from "./pages/Home/Support";
import ContactUs from "./pages/ContactUs/ContactUs";
import AboutUs from "./pages/AboutUs/AboutUs";
import Blog from "./pages/Blog/Blog"; 
import HomeBlogs from "./pages/Home/HomeBlogs"; 
import Products from "./pages/Products/Products";
import TheRiseofPixalive from "./pages/Blog/TheRiseofPixalive";
import PixaliveBengaluruOffice from "./pages/Blog/PixaliveBengaluruOffice";
import WhatAreTheMostPopularApps from "./pages/Blog/WhatAreTheMostPopularApps";
import HeloAppAlternativesinIndiaLikePixalive from "./pages/Blog/HeloAppAlternativesinIndiaLikePixalive";
import PixaliveappisNowtrendinginIndia from "./pages/Blog/PixaliveappisNowtrendinginIndia";
import Investors from "./pages/Investors/Investors";


const routes = (
  <Router>    
    <Routes>
      <Route path='/' element={<Home/>} />
      <Route path='/Banner' element={<Banner/>} />
      <Route path='/About' element={<About/>} />
      <Route path='/Startups' element={<Startups/>} />
      <Route path='/OurMission' element={<OurMission/>} />
      <Route path='/SupportedStartups' element={<SupportedStartups/>} />
      <Route path='/Support' element={<Support/>} />
      <Route path='/HomeBlogs' element={<HomeBlogs/>} />  
      <Route path='/AboutUs' element={<AboutUs/>} />
      <Route path='/ContactUs' element={<ContactUs/>} />
      <Route path='/Blog' element={<Blog/>} />   
      <Route path='/Products' element={<Products/>} /> 
      <Route path='/TheRiseofPixalive' element={<TheRiseofPixalive/>} />
      <Route path='/PixaliveBengaluruOffice' element={<PixaliveBengaluruOffice/>} />
      <Route path='/WhatAreTheMostPopularApps' element={<WhatAreTheMostPopularApps/>} />
      <Route path='/HeloAppAlternativesinIndiaLikePixalive' element={<HeloAppAlternativesinIndiaLikePixalive/>} />
      <Route path='/PixaliveappisNowtrendinginIndia' element={<PixaliveappisNowtrendinginIndia/>} />
      <Route path='/Investors' element={<Investors/>} />
      
    </Routes>
 </Router> )
function App() {
  return (
    <>
        {routes}  
    </>
  );
}

export default App;
