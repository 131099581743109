
function Banner() { 
	return( 
	<>
    <div class="container-fluid slider-container">
    <div id="carousel" class="carousel slide" data-ride="carousel">
        {/* <ol class="carousel-indicators">
          <li data-target="#carousel" data-slide-to="0" class="active"></li>
          <li data-target="#carousel" data-slide-to="1"></li>
          <li data-target="#carousel" data-slide-to="2"></li>
        </ol> */}
        <a class="" href="#carousel" role="button" data-slide="prev">
        <i class="hero__prev-arrow far fa-long-arrow-left slick-arrow"  ></i>
          <span class="hero__prev-arrow far fa-long-arrow-left slick-arrow" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carousel"  data-slide="next">
        <i class="hero__next-arrow far fa-long-arrow-right "  ></i>
          <span class="hhero__next-arrow far fa-long-arrow-right slick-arrow" aria-hidden="true"></span>
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
        <div class="hero--active carousel-inner" role="listbox">

          <div class="carousel-item active slider-1 hero-single hero__padding hero__padding--h5" >
             <div class="col-lg-8 offset-lg-2 text-center">
                <div class="hero__caption hero__caption--h5">
                    <h5 class="hero--small-title white-color fw-500" data-animation="fadeInDown"
                        data-delay=".2s"><span></span>Pixalive Platforms<span></span></h5>
                    <h1 class="hero--big-title white-color fw-700" data-animation="fadeInDown"
                        data-delay=".4s">Connecting Minds, Empowering Businesses: Your Privacy-Centric Network</h1>
                    <p>Our platform serves as a networking tool and a privacy-centric solution for connecting like-minded people & promoting businesses</p>
                    <a href="#" class="d-link border-link-banner">View More </a>
                </div>
             </div>
          </div>

          <div class="carousel-item slider-2 hero-single hero__padding hero__padding--h5">
            <div class="col-lg-8 offset-lg-2 text-center">
                <div class="hero__caption hero__caption--h5">
                    <h5 class="hero--small-title white-color fw-500" data-animation="fadeInDown"
                        data-delay=".2s"><span></span>Pixalive  Entertainment Application<span></span></h5>
                    <h1 class="hero--big-title white-color fw-700" data-animation="fadeInDown"
                        data-delay=".4s">Enjoy your leisure time while also earning money through your daily activities on our application</h1> 
                        <p>Download our application from the Play Store or App Store to Join</p>
                        <a href="https://play.google.com/store/search?q=pixalive+app&c=apps&hl=en-IN" target="_blank" class="store-btn"><img src="/assets/images/play-store.svg" alt=""/></a>
                        <a href="https://apps.apple.com/in/app/pixalive/id1491966281" target="_blank" class="store-btn"><img src="/assets/images/app-store.svg" alt=""/></a>
                </div>
            </div> 
          </div>
          <div class="carousel-item slider-2 hero-single hero__padding hero__padding--h5">
            <div class="col-lg-8 offset-lg-2 text-center">
                <div class="hero__caption hero__caption--h5">
                    <h5 class="hero--small-title white-color fw-500" data-animation="fadeInDown"
                        data-delay=".2s"><span></span>Webalive Business Application<span></span></h5>
                    <h1 class="hero--big-title white-color fw-700" data-animation="fadeInDown"
                        data-delay=".4s">Both business and common users can share & post website links, Play Store and App Store links, and list products in a shopping cart</h1> 
                        <p>Download our application from the Play Store or App Store to Join</p>
                        <a href="#" target="_blank" class="store-btn"><img src="/assets/images/play-store.svg" alt=""/></a>
                        <a href="#" target="_blank" class="store-btn"><img src="/assets/images/app-store.svg" alt=""/></a>
                </div>
            </div> 
          </div>

        </div>
        
        

      </div>
    </div>

  </> 
  ); 
  } 
  export default Banner;