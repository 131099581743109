
function Support() { 
	return( 
	<>  
    
    <section id="home_cor_social_val" class="mt-5">
      <div class="container-fluid max_container h-100">
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="text wow fadeInUp">
              <div class="title left_title_line">
                <h5>Corporate Social Value</h5>
                <h2 class="h2 text-white">Building a better <span>tomorrow</span></h2>
              </div>
              <p class="text-white">The commitment to sustainable social progress has always been enshrined in pixalive's way of working, in the communities we influence, the families we're part of and the entrepreneurs we partner with.</p>
              <a href="csr.html" class="btn blue_btn rounded-0 mt-4">Know more</a>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="home_cor_social_val_right wow fadeInUp" data-wow-delay="0.4s">
              <div class="hcsv_right_text">
                <h6>Our Corporate Social Value creation focuses on 3 E's -</h6>
                <h4>Education, Empowerment, Environment and Health.</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </> 
  ); 
  } 
  export default Support;