
function SupportedStartups() { 
	return( 
	<>  
        
        <section id="home_cor_social_val" class="mt-5" >
      <div class="container-fluid max_container h-100">
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="text wow fadeInUp"  >
              <div class="title left_title_line">
                <h5>Download</h5>
                <h2 class="h2 text-white">Download our Application now!</h2>
              </div>
              <p class="text-white">Enjoy the application by downloading and installing it from both the Play Store and the App Store. </p>
 
              <a href="#" class="d-link border-link text-white">View More</a>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="home_cor_social_val_right wow fadeInUp" data-wow-delay="0.4s"  >
              <div class="hcsv_right_text">
                <h6>Theme</h6>
                <h4>Connecting Minds, Empowering Businesses:Your Privacy-Centric Network.”</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    
  </> 
  ); 
  } 
  export default SupportedStartups;