
function Startups() { 
	return( 
	<>  
    
    <section class="pixalive-intro mt-5">
					<div class="container-fluid">
						<div class="row">
							<div class="col-md-5">
								<h6 class="gradient-text">PRODUCTS</h6>
								<h2 class="big-heading2 mb-lg-5 mb-4">
								Connecting Minds, Empowering Businesses:<br/> 
								Your Privacy-Centric Network
								</h2> 								
								<a target="_blank" class="border-link" href="#" tabindex="-1">Read More</a>
							</div>
							<div class="col-md-7 d-flex-data pl-lg-5">
								<button class="accordion active noneAccordian">
                  					<img src="../assets/images/plus-svgrepo-com.webp" alt="plus icon"/>
									<h4>Pixalive</h4>
								</button>
								<div class="panel panelMaxHeightAccordin sustainableApproachSectionAccordin">
									<a href="environment.html" class="d-flex">
									{/* <div class="acc-img-div">
										<img src="../assets/images/a1.jpg" alt="Environment img"/>
									</div> */}
									<div class="text-panel word-brek-style">
										<h5>Pixalive</h5>
										<p>Pixalive stands as India's pioneering interest-based social media networking application, offering rewards to all users on its platform. It empowers regular users to generate earnings through their daily activities and engagement within the app. </p>
										<span class="border-link">View More</span>
									</div>
									</a>
								</div>

								<button class="accordion"><img src="../assets/images/plus-svgrepo-com.webp" alt="plus icon"/>
									<h4>Webalive </h4>
								</button>
								<div class="panel">
									<a href="/" class="d-flex">
									{/* <div class="acc-img-div">
										<img src="../assets/images/a2.jpg" alt="Social icon"/>
									</div> */}
									<div class="text-panel  word-brek-style">
										<h5>Webalive</h5>
										<p>It's a business application platform where both business users and common users can post their website links, links to the Play Store and App Store, and list their products in a shopping cart. </p>
										 <span class="border-link">View More</span> 
									</div>
									</a>
								</div>

								 
							 
							</div>
						</div>
					</div>
				</section>
  </> 
  ); 
  } 
  export default Startups;