
import Header from '../../components/Header'; 
import Footer from '../../components/Footer'; 

function Investors() { 
	return( 
	<>
    <div id="body">
    <Header />
    <main class="inner-page" id="main-container">
        <section class="inner-page-banner">
            <div class="card inner-banner-image">
                <div class="card-img-overlay">
                    <div class="breadcrumb">
                        <a href="/">Home</a> <span class="seperator">/</span>
                        <a href="/Investors">Investors</a> 
                    </div>
                    <h1 class="card-title">Investors</h1>
                </div>
            </div> 
        </section>

        <section class="section supercharging-highlighted-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 about-flex">
                        <div class="supercharging-highlight-infobox">
                            <h2>Welcome Investors!</h2>
                            <p>We welcome passionate Individuals, Angel Investors, Venture Capitalists, Syndicate and Organizations to join us in investing in our company and becoming integral to our journey and growth. We are thrilled to have you on board as part of our exciting journey towards success. </p>
                        </div>
                    </div>
                    <div class="col-lg-6 about-flex">
                        <img src="assets/images/investors.jpg" alt="Image Not Found" />
                    </div>
                </div>
            </div>
        </section>
        <section class="section bg-gray three-column-white-card-wrap supercharging-highlighted-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-6 three-column-white-card-column">
                        <div class="three-column-white-card-item">
                            <div class="three-column-white-card-content">
                                <h4>Why Pixalive Platforms?</h4>
                                <p>We aim to develop social media applications that provide solutions to the challenges people face in the modern world. We also seek to encourage and empower the Indian community. Our ultimate goal is to elevate our company to the status of a 'Unicorn' while maintaining the highest standards of privacy.</p>
                            </div> 
                        </div>
                    </div>  
                    <div class="col-lg-4 col-md-6 three-column-white-card-column">
                        <div class="three-column-white-card-item">
                            <div class="three-column-white-card-content">
                                <h4>Transparency & Communication</h4>
                                <p>We believe in transparent and effective communication. As an investor, you will receive regular updates on our financial performance, strategic plans, and company updates. We want you to stay informed and engaged in our progress.</p>
                            </div> 
                        </div>
                    </div>  
                    <div class="col-lg-4 col-md-6 three-column-white-card-column">
                        <div class="three-column-white-card-item">
                            <div class="three-column-white-card-content">
                                <h4>Engage & Connect</h4>
                                <p>Your insights and feedback are extremely important to us. We invite you to participate in investor meetings, webinars, and Q&A sessions. We cherish your perspective, knowing that constructive conversations pave the way for our mutual success. Your active involvement is vital to our progress.</p>
                            </div> 
                        </div>
                    </div>  
                    <div class="col-lg-4 col-md-6 three-column-white-card-column"> 
                    </div> 
                    <div class="col-lg-4 col-md-6 three-column-white-card-column">
                        <div class="three-column-white-card-item">
                            <div class="three-column-white-card-content">
                                <h4>Your Investment, Our Future</h4>
                                <p>Your investment in Pixalive Group signifies your trust in our potential. </p>
                                <p>Together, we are set to achieve great things and provide value. We are dedicated to keeping our promises and ensuring your investment is valuable.</p>
                            </div> 
                        </div>
                    </div> 
                    <div class="col-lg-4 col-md-6 three-column-white-card-column"> 
                    </div> 
                    <div class="col-xl-12 col-lg-12">                        
                        <div class="pricing-style-one active">
                            <div>
                                <h4>Thanking Note for Previous Investors & Ambassadors!</h4>
                                <span>We are taking this platform as an opportunity to thank our previous investors who trusted, invested, and supported our exponential growth what we achieved today. </span> 
                                <span>Thank Indian sportsperson and Volleyball Player <a>Miss. Disha Ghosh</a> for your meritorious service as our First brand ambassador. </span>
                                <span>Thank actress and entrepreneur <a>Mrs. Pranitha Subhash</a> for your meritorious service as our brand ambassador. </span>
                            </div> 
                        </div>
                    </div>
                    

                    
                     
                </div> 

                <div class="row mt-5 pt-lg-4 ambassadors-space">
                    <div class="col-lg-4 col-md-4">
                        <div class="ambassadors-image">
                            <img src="../assets/images/brand-ambassador/disha.jpg" alt="Pixalive" />   
                        </div>                     
                        <div class="ambassadors-name">
                            <h3>Miss. Disha Ghosh</h3>
                            <h4>Brand Ambassador, Indian Volleyball Player</h4>
                        </div>                        
                    </div>
                    <div class="col-lg-4 col-md-4">
                        <div class="ambassadors-image">
                            <img src="../assets/images/brand-ambassador/pranitha.jpg" alt="Pixalive" />   
                        </div>                       
                        <div class="ambassadors-name">
                            <h3>Mrs. Pranitha Subhash</h3>
                            <h4>Brand Ambassador, Indian Actress</h4>
                        </div>                        
                    </div>
                    <div class="col-lg-4 col-md-4">
                        <div class="ambassadors-image">
                            <img src="../assets/images/brand-ambassador/question-mark.jpg" alt="Pixalive" />   
                        </div>                        
                        <div class="ambassadors-name">
                            <h3>Who’s Next</h3>
                            <h4>Brand Ambassador</h4>
                        </div>                        
                    </div>
                    <div class="col-lg-4 col-md-4">
                        <div class="ambassadors-image">
                            <img src="../assets/images/brand-ambassador/pranika.jpg" alt="Pixalive" />   
                        </div>                        
                        <div class="ambassadors-name">
                            <h3>Miss. Pranika Dhakshu</h3>
                            <h4>Supporter, Actress</h4>
                        </div>                        
                    </div>
                    <div class="col-lg-4 col-md-4">
                        <div class="ambassadors-image">
                            <img src="../assets/images/brand-ambassador/anitha.jpg" alt="Pixalive" />   
                        </div>                        
                        <div class="ambassadors-name">
                            <h3>Mrs. Anitha Sampath</h3>
                            <h4>Supporter, Actress</h4>
                        </div>                        
                    </div>
                    <div class="col-lg-4 col-md-4">
                        <div class="ambassadors-image">
                            <img src="../assets/images/brand-ambassador/mathan.jpg" alt="Pixalive" />   
                        </div>                        
                        <div class="ambassadors-name">
                            <h3>Mr. Madhan Babu</h3>
                            <h4>Supporter, Actor</h4>
                        </div>                        
                    </div>
                    {/* <div class="col-lg-4 col-md-4">
                        <div class="ambassadors-image">
                            <img src="../assets/images/brand-ambassador/1.jpeg" alt="Pixalive" />   
                        </div>                        
                        <div class="ambassadors-name">
                            <h3>Mr. Rajasekar Sundaresan</h3>
                            <h4>CEO, Pixalive Platforms</h4>
                        </div>                        
                    </div> */}
                </div>
                {/* <div class="col-xl-12 col-lg-12">                        
                        <div class="pricing-style-one active">
                            <div>
                                <h4>Thanking Note for Previous Investors & Ambassadors!</h4>
                                <span>We are taking this platform as an opportunity to thank our previous investors who trusted, invested, and supported our exponential growth what we achieved today. </span> 
                                <span>Thank Indian sportsperson and Volleyball Player <a>Miss. Disha Ghosh</a> for your meritorious service as our First brand ambassador. </span>
                                <span>Thank actress and entrepreneur <a>Mrs. Pranitha Subhash</a> for your meritorious service as our brand ambassador. </span>
                            </div> 
                        </div>
                    </div> */}
                 
                <div class="col-xl-12 col-lg-12">           
                        <div class="pricing-style-one active">
                            <div>
                                <h4>Contact Us</h4>
                                <span>If you have any questions or need assistance, please feel free to reach out to our Investor Relations team at <a>investor@pixaliveplatform.com</a></span>
                                <span>We are here to support you in every step of your journey with us.</span>
                            </div> 
                        </div>
                    </div> 

            </div> 
        </section>  

        {/* <section class="office-networks scaling">
         <div class="container">
            <div class="row">
               <h2>Contact Us</h2>
               <div class="col-md-12 d-flex-data">
                  <button class="contact-accordion">
                     <img src="assets/images/plus-svgrepo-com.png" alt="Plus Icon" />
                     <h4> Pixalive Platforms</h4>
                  </button>
                  <div class="panel">
                     <button type="button" class="close">
                        <div class="acc-img-div" onclick="#">
                           <img src="assets/images/minus-sign.png" alt="Minus Icon" /> 
                        </div>
                     </button>
                     <div class="text-panel">
                        <h5> Pixalive Platforms</h5>
                        <h6>Join us in the dynamic world of Social Media.</h6>                      
                     </div>
                  </div>
                  <button class="contact-accordion">
                     <img src="assets/images/plus-svgrepo-com.png" alt="Plus Icon" />
                     <h4> Pixalive Labs</h4>
                  </button>
                  <div class="panel">
                     <button type="button" class="close">
                        <div class="acc-img-div" onclick="#">
                           <img src="assets/images/minus-sign.png" alt="Minus Icon" /> 
                        </div>
                     </button>
                     <div class="text-panel">
                        <h5>Pixalive Labs</h5>
                        <h6>Invest in talents, change their lifestyle.</h6>                      
                     </div>
                  </div>

                  <button class="contact-accordion">
                     <img src="assets/images/plus-svgrepo-com.png" alt="Plus Icon" />
                     <h4>Pixalive Works</h4>
                  </button>
                  <div class="panel">
                     <button type="button" class="close">
                        <div class="acc-img-div" onclick="#">
                           <img src="assets/images/minus-sign.png" alt="Minus Icon" /> 
                        </div>
                     </button>
                     <div class="text-panel">
                        <h5>Pixalive Works</h5>
                        <h6>Explore opportunities in our Software as a Service (SaaS) division.</h6>                      
                     </div>
                  </div>
                  <button class="contact-accordion">
                     <img src="assets/images/plus-svgrepo-com.png" alt="Plus Icon" />
                     <h4>Pixalive Ventures</h4>
                  </button>
                  <div class="panel">
                     <button type="button" class="close">
                        <div class="acc-img-div" onclick="#">
                           <img src="assets/images/minus-sign.png" alt="Minus Icon" /> 
                        </div>
                     </button>
                     <div class="text-panel">
                        <h5>Pixalive Ventures</h5>
                        <h6>Dive into the exciting realm of Startups.</h6>                      
                     </div>
                  </div>
                  <button class="contact-accordion">
                     <img src="assets/images/plus-svgrepo-com.png" alt="Plus Icon" />
                     <h4>Pixalive Consultancy Services</h4>
                  </button>
                  <div class="panel">
                     <button type="button" class="close">
                        <div class="acc-img-div" onclick="#">
                           <img src="assets/images/minus-sign.png" alt="Minus Icon" /> 
                        </div>
                     </button>
                     <div class="text-panel">
                        <h5>Pixalive Consultancy Services</h5>
                        <h6>Invest in our IT Services segment.</h6>                      
                     </div>
                  </div>
                 
              </div> 
            </div> 
          </div> 
        </section> 
                  */}



      
    <Footer/>


    </main>
    </div> 
    </> 
    ); 
  } 
  export default Investors;