import Header from '../../components/Header';  
import Footer from '../../components/Footer';
function PixaliveBengaluruOffice() { 
	return( 
	<>  
    
    <div id="body">
      <Header />
      <main class="inner-page" id="main-container">
      
      <section class="inner-page-banner">
         <div class="card inner-banner-image">
               <div class="card-img-overlay">
                  <div class="breadcrumb">
                     <a href="/">Home</a> <span class="seperator">/</span>
                     <a href="/AboutUs">Blog</a> 
                  </div>
                  <h1 class="card-title">Our Blog</h1>
               </div>
            </div> 
         </section>
      <div class="paragraph">
         <div class="container">
            <div class="row articles-lower ">
               <div class="col-md-8">
                     <div class="article_title">
                        <div class="editorial">
                           <a href="#">Investing</a>
                        </div>
                        <h2 class="node__title">
                           <span>The Rise of Pixalive: Pioneering a New Era in India’s Social Media Landscape</span>
                        </h2>
                        <div class="short-desc">
                        Upon hearing the controversial statement made by the CEO of Snapchat, who stated, “This app is only for rich people. I do not want to expand into poor countries like India and Spain,” Rajasekar was inspired to act.
                        </div>
                     </div>
                     <div class="articles-upper">
                        <div class="authors authors-box"> 
                           <div class="blogger-inforow">
                              <div class="blogger-pic">
                                 <img src="../assets/images/team/vignesh.jpg" alt="Jaydeep Saha " title="Jaydeep Saha "/>
                              </div>
                              <div class="blogger-details">
                                 <div class="blogger-name">Vignesh Dhanasekaran </div>
                                 <div class="blogger-designation">COO, Pixalive Group </div>
                              </div>
                           </div>
                           <div class="article-details">
                              <div class="date desktop">
                                 <div class="field field--name-field-page-publish-date field--type-datetime field--label-hidden field__item">
                                    <time datetime="2023-08-29T12:00:00Z" class="datetime">August 29, 2023</time>
                                 </div>
                              </div>
                              <div class="read-time desktop">7 minutes</div>
                              <div class="socila-media">
                                 <span>Share</span>
                                 <ul>
                                    <li>
                                    <a href="#" aria-label="facebook" target="blank" >
                                       <img class="img-fluid" src="./assets/images/facebook.svg" /> 
                                    </a>
                                    </li>
                                    <li>
                                    <a href="#" aria-label="twitter" target="blank">
                                       <img class="img-fluid" src="./assets/images/twitter.svg" />
                                    </a>
                                    </li>

                                    <li>
                                    <a href="#" aria-label="linkedin" target="blank">
                                       <img class="img-fluid" src="./assets/images/linkedin.svg" />
                                    </a>
                                    </li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div class="article-image article-image-wd"> 
                           <img src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhmyH6q-4_QRRYjN0MqiUEHOyJ1fWU0a8fWl6X4po-qS6675fMcoUjI4QToA82V7zV489d2ySgRten60sx9lJAlUpY-fAvDrhBTZz6atptsSVrycSnO1FDgLSoYJ9nDvtnXauZCLFpaGSdBw4xYLeB6sLMeEjH0wbD81vWUWMlw7FGH1y-gIbMfZ3nI56cJ/w298-h240/Pixalive%20Pooja.jpeg" width="700" height="357" alt=""/>
                        </div>                       
                     </div>
                     <div class="articles-lower">
                        <div class="article-body">
                           <div class="field--name-body field--name-body-1">
                              <h6>Introduction</h6>
                              <p>In today's fast-paced corporate world, where innovation and collaboration are the keys to success, a new office space can be a game-changer for any organization. It symbolizes growth, progress, and the commitment to providing a conducive environment for employees to thrive. In this blog, we take you through the journey of our recent office inauguration – a grand celebration of our company's expansion and dedication to excellence.</p>
                              <div class="blog-inner-two-image-cn"> 
                                 <div class="blog-inner-two-image"> 
                                    <img src="../assets/images/blog/office-1.jpeg" alt=""/>
                                    <img src="../assets/images/blog/office-2.jpeg" alt=""/>                     
                                 </div>  
                                 <div>                                 
                                    <span>CEO & COO Cabin</span>
                                 </div>
                              </div>                          
                              <h6>Pixalive's Leap of Determination</h6>                        
                              <p>Pixalive achieved a remarkable milestone on August 28th, 2023, with the opening of our new office in Bengaluru. This achievement was made possible through the unwavering determination of Mr. Rajasekar Sundaresan and the invaluable guidance and support of Mr. Vignesh Dhanasekaran, who navigated Pixalive through challenging times.</p> 
                              <h6>A Testament to Progress</h6>
                              <p>Our new office in Bengaluru stands as a testament to the progress and growth that Pixalive has achieved. It signifies our dedication to reaching new heights and providing our team with a space that fosters creativity, innovation, and collaboration.</p> 
                              <h6>Strategic Location</h6>
                              <p>Situated at a strategic location just 900 meters from the Hosa Road Metro Station, 800 meters from the Hosa Road Bus Stop, and 800 meters from the Beratena Agrahara Metro Station, our office is easily accessible to our team members and clients alike. Additionally, being only 150 meters away from the Electronic City Expressway flyover road point, The presence of the Metro nearby adds a significant advantage, allowing for convenient transportation options in the bustling city.</p> 
                              <h6>A Dedicated Team for a Common Goal</h6>
                              <p>Pixalive's current team size comprises 20+ dedicated employees, all working towards a common goal. This diverse and passionate team is the driving force behind our success and growth.</p> 
                              <h5>The Significance of Office Inaugurations</h5>
                              <h6>1: The Vision</h6>
                              <p>Every great project begins with a vision. Our journey towards the inauguration of our new office started with a vision of creating a workplace that would not only meet the needs of our growing team but also inspire creativity and foster a sense of community. We wanted an office that reflected our company's values and culture.</p> 
                              <div class="center-image center-image-wdh">
                                 <img src="../assets/images/blog/office-3.jpg" alt=""/>
                                 <span>Pixalive CEO, Mr. & Mrs. Rajasekar</span>
                              </div>
                              <h6>2: The Inauguration Day</h6>                              
                              <p>The day of the office inauguration was a momentous occasion for our company. We invited employees, clients, partners, and stakeholders to witness the grand unveiling. The ceremony began with a Ganapathi Pooja & Lakshmi Pooja ritual by our CEO & COO, followed by speeches that highlighted our company's journey and vision for the future.</p> 
                              <div class="center-image center-image-wdh-1">
                                 <img src="../assets/images/blog/office-4.jpg" alt=""/>
                                 <span>Pixalive COO, Vignesh Dhanasekaran</span>
                              </div>
                              <h6>3: Cutting-Edge Technology</h6>
                              <p>To keep pace with the demands of the digital age, our new office is equipped with state-of-the-art technology. High-speed internet, video conferencing facilities, and smart meeting rooms ensure seamless communication and collaboration among our teams, whether they are in the office or working remotely.</p>                               
                              <h6>4: Employee Experience</h6>
                              <p>Our employees are the heart and soul of our organization, and their experience in the new office was a top priority. We have created comfortable breakout areas, a well-stocked pantry, and relaxation zones to ensure they have spaces to unwind and recharge. An office inauguration can be a morale booster for employees. It shows them that their hard work and dedication have contributed to the company's growth and prosperity. It fosters a sense of pride and belonging.</p> 
                              <div class="center-image center-image-wdh-1">
                                 <img src="../assets/images/blog/office-5.jpeg" alt=""/>
                                 <span>Pixalive Team</span>
                              </div>
                              <h6>5: Future Growth</h6>
                              <p>Our new office space is not just a reflection of our present success; it's also a symbol of our commitment to future growth. With ample room for expansion, we look forward to welcoming new talents and fostering more innovation in the years to come.</p> 
                              <h6>6. Milestone Achievement</h6>
                              <p>An office inauguration represents a tangible testament to an organization's progress and achievements. It signifies that the company has reached a point where it necessitates a larger space to accommodate its burgeoning growth.</p>
                              <h6>7. Branding and Image:</h6>
                              <p>A well-organized office inauguration can significantly bolster a company's branding and image. It sends a resounding message to the industry and the market, positioning the organization as a formidable player.</p>
                              <h5>Conclusion:</h5>
                              <p>The inauguration of our new office in Bengaluru marked a significant milestone in Pixalive's journey. It embodies our commitment to growth, sustainability, and creating an exceptional work environment for our employees. </p>
                              <p>As we stride confidently into the future, we are brimming with excitement about the opportunities and possibilities that this new space will usher in. We eagerly await the ways in which it will shape the future of our organization. With our dedicated team and visionary leadership, Pixalive is poised to scale new heights of success in the years ahead.</p>
                           </div>
                        </div>
                        
                        <div class="article-body mobile-show">
                           <div class="articles-tags">TAGS: <div class="field field--name-field-free-tags field--type-entity-reference field--label-hidden field__items">
                              <div class="field__item">Social Media</div>
                              <div class="field__item">Trending</div>
                              <div class="field__item">Hashtag</div> 
                              <div class="field__item">Startup</div>
                              <div class="field__item">Fundraising</div>
                              <div class="field__item">Investing</div>
                              <div class="field__item">Technology</div>
                           </div>
                           </div>
                           <div class="socila-media">
                           <span>Share On</span>
                           <ul>
                              <li>
                                 <a href="#" aria-label="facebook" target="blank" >
                                    <img class="img-fluid" src="./assets/images/facebook.svg" /> 
                                 </a>
                              </li>
                              <li>
                                 <a href="#" aria-label="twitter" target="blank">
                                    <img class="img-fluid" src="./assets/images/twitter.svg" />
                                 </a>
                              </li>
                              <li>
                                 <a href="#" aria-label="linkedin" target="blank">
                                    <img class="img-fluid" src="./assets/images/linkedin.svg" />
                                 </a>
                              </li>
                           </ul>
                           <div class="copy-link">
                              <span>Copy link</span>
                              <a id="copy_link" href="javascript:;" cururl="#">
                                 <img src="../assets/images/copy-link.png" alt="copy-link" width="100" height="100"/>
                              </a> 
                           </div>
                        </div>
                     </div>
                  </div>                        
               </div>

               <div class="col-md-4 cta-forms">
                  <div class="inner-cta-forms-trends mobile-f-w"> 
                     <div class="right-banner">
                        <div class="field field--name-field-sidebar-ad field--type-entity-reference field--label-hidden field__item">
                           <div class="eck-entity">
                           <div class="right-banner"> 
                                 <img src="../assets/images/ads.jpg" title=""/>
                              </div>
                              <div class="content">
                                 <h4 class="base-color-white brand-no-case">Golden Period</h4>
                                 <p>Pixalive inaugurated its new office space in Bengaluru on August 28, 2023, marking the beginning of a golden era for the company. </p>
                                 <p>Within just one month, on September 27, 2023, it secured a dedicated office space and already employed more than 30 individuals.</p>
                                 <p>It has expanded into five new divisions apart from its Social Media division, including Startup Support, SaaS, IT Services, Software Training, and Sustainability.</p>
                                 <a class="arrow-btn-white" href="/PixaliveBengaluruOffice">View More</a>                              
                              </div> 
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

            </div>
         </div> 
      </div>
      
                  
      <Footer/>
      </main>
   </div> 

  </> 
  ); 
  } 
  export default PixaliveBengaluruOffice;