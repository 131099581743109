
function HomeBlogs() { 
	return( 
	<>
     

	 <section class="leadership2" id="leaders">
		<div class="container-fluid">
			<div class="row text-center">
			<h6 class="gradient-text m-auto">THE TEAM</h6>
			<h2 class="big-heading2"> Platforms Leadership </h2>
			</div>
			<div class="row mt-5 pt-lg-4 leadership-space">
			<div class="card">
				<img src="../assets/images/team/rajasekar.jpg" alt="Pixalive" />
				<div class="card-footer">
				<div class="name-div">
					<h3>Mr. Rajasekar Sundaresan</h3>
				<span>CEO, Pixalive Platforms</span>
				</div>
				</div>
			</div>
			<div class="card">
				<img src="../assets/images/team/3.jpg" alt="Pixalive" />
				<div class="card-footer">
				<div class="name-div">
					<h3>Mr. Nitesh Jain</h3>
				<span>Director, Pixalive Platforms</span>
				</div>
				</div>
			</div>
			<div class="card">
				<img src="../assets/images/team/4.jpg" alt="Pixalive" />
				<div class="card-footer">
				<div class="name-div">
					<h3>Mr. Abhishek Jain</h3>
				<span>Director, Pixalive Platforms</span>
				</div>
				</div>
			</div>
			<div class="card">
				<img src="../assets/images/team/2.jpg" alt="Pixalive" />
				<div class="card-footer">
				<div class="name-div">
					<h3>Mr. Bharath Kumar Jain</h3>
				<span>Legal Advisor to CEO</span>
				</div>
				</div>
			</div>
			<div class="card">
				<img src="../assets/images/team/vignesh.jpg" alt="Pixalive" />
				<div class="card-footer">
				<div class="name-div">
					<h3>Mr. Vignesh Dhanasekaran</h3>
				<span>COO, Pixalive Platforms</span>
				</div>
				</div>
			</div>
			<div class="card">
				<img src="../assets/images/team/sathish.jpg" alt="Pixalive" />
				<div class="card-footer">
				<div class="name-div">
					<h3>Mr. Sathish Kumar</h3>
				<span>Technical Advisor to CEO</span>
				</div>
				</div>
			</div>
			<div class="card">
				<img src="../assets/images/team/murali.jpg" alt="Pixalive" />
				<div class="card-footer">
				<div class="name-div">
					<h3>Mr. Murali Sundaresan</h3>
				<span>Director, Pixalive Platforms</span>
				</div>
				</div>
			</div> 
			<div class="card">
				<img src="../assets/images/team/viji.jpg" alt="Pixalive" />
				<div class="card-footer">
				<div class="name-div">
					<h3>Mr. Viji Vaithi</h3>
				<span>Group UI/UX Design Lead</span>
				</div>
				</div>
			</div>
			
			
			</div>
		</div>
	</section>


	<section class="news-section">
		<div class="container-fluid">
			<div class="row">
				<div class="col-md-12 pl-lg-4 ml-lg-2 text-center">
					<h6 class="gradient-text">Latest Updates, Blogs & News</h6>
					<h2 class="big-heading2 mb-lg-4 mb-4">Stay updated on our recent activities here</h2>
				</div>
			</div>
			<div class="row">
				<div class="news-slider">
					<div class="blog-box load-box">
						<div class="card">
							<img src="./assets/images/blog/blog-1.jpg" alt="Media Img" class="w-100" />
							<div class="container">
							<span class="tag-date"><a href="/TheRiseofPixalive">Social Media</a></span>
							<div class="blog-title">
								<h4>
								<a href="/TheRiseofPixalive">
									The Rise of Pixalive: Pioneering a New Era in India’s Social Media Landscape </a>
								</h4>
								<p>It was during this challenging time that Dr. Abdul Kalam, a visionary leader, and renowned figure, stepped in to provide crucial support...</p>
								<a class="border-link" href="/TheRiseofPixalive">Read More</a>
							</div>
							</div>
						</div>
					</div> 
					<div class="blog-box load-box">
						<div class="card">
							<img src="./assets/images/blog/pixalive-pooja.jpg" alt="Media Img" class="w-100" />
							<div class="container">
							<span class="tag-date"><a href="/PixaliveBengaluruOffice">Investing</a></span>
							<div class="blog-title">
								<h4><a href="/PixaliveBengaluruOffice">
								Pixalive's Bengaluru Office: Transforming Vision Into Reality</a>
								</h4>
								<p> In today's fast-paced corporate world, where innovation and collaboration are the keys to success, a new office space can be a game-changer...</p>
                                             
								<a class="border-link" href="/PixaliveBengaluruOffice">Read More</a>
							</div>
							</div>
						</div>
					</div> 
					{/* <div class="blog-box load-box">
						<div class="card">
							<img src="./assets/images/blog/blog-5.jpg" alt="Media Img" class="w-100" />
							<div class="container">
							<span class="tag-date"><a href="/WhatAreTheMostPopularApps">Social Made</a></span>
							<div class="blog-title">
								<h4><a href="/WhatAreTheMostPopularApps">
								What are the 10 most popular apps?</a>
								</h4>
								<p>Now that we are into 2020 and must have come across these most popular apps and on the trend...</p>
                                <a class="border-link" href="/WhatAreTheMostPopularApps">Read More</a>
							</div>
							</div>
						</div>
					</div>
					<div class="blog-box load-box">
						<div class="card">
							<img src="./assets/images/blog/blog-3.jpg" alt="Media Img" class="w-100" />
							<div class="container">
							<span class="tag-date"><a href="/HeloAppAlternativesinIndiaLikePixalive">Social Media</a></span>
							<div class="blog-title">
								<h4><a href="/HeloAppAlternativesinIndiaLikePixalive">
								Helo App Alternatives in India Like Pixalive, Roposo & ShareChat</a>
								</h4>
								<p>Pixalive, Roposo, and ShareChat have taken the Chinese Helo app’s market after its ban, along with several other popular Chinese apps...</p>
                                            <a class="border-link" href="/HeloAppAlternativesinIndiaLikePixalive">Read More</a>
							</div>
							</div>
						</div>
					</div> */}
					<div class="blog-box load-box">
						<div class="card">
							<img src="./assets/images/blog/blog-4.jpg" alt="Media Img" class="w-100" />
							<div class="container">
							<span class="tag-date"><a href="/PixaliveappisNowtrendinginIndia">Social Media</a></span>
							<div class="blog-title">
								<h4><a href="/PixaliveappisNowtrendinginIndia">
								Pixalive app is Now trending in India!</a>
								</h4>
								<p>Ever since the ban of the Chinese app by The Ministry of Electronics and Information Technology, India, social media users have been in a turbulence...</p>
                                            <a class="border-link" href="/PixaliveappisNowtrendinginIndia">Read More</a>
							</div>
							</div>
						</div>
					</div>
					
				</div>
			{/* <ul class="news-controls">
				<li class="news-prev">
				<div class="per-arrow-left"></div>
				</li>
				<li class="news-next">
				<div class="per-arrow-right"></div>
				</li>
			</ul> */}
			</div>
		</div>
	</section>

	<section class="news-section">
		<div class="container-fluid">
			<div class="row">
				<div class="col-md-12 pl-lg-4 ml-lg-2 text-center">
					<h6 class="gradient-text">Pixalive Lauch Events</h6>
					<h2 class="big-heading2 mb-lg-4 mb-4">Enjoy our launch event videos on YouTube</h2>
				</div>
			</div>
			<div class="row">
				<div class="news-slider">
					<div class="blog-box load-box">
						<div class="card">
							<img src="./assets/images/blog/y1.jpg" alt="Media Img" class="w-100" />
							<div class="container">
							 <div class="blog-title mt-4">
								<h4><a target="_blank" href="https://www.youtube.com/watch?v=KBkyj-qMO_k">Owner and Founder Of Pixalive App Rajasekar Tamilan</a> </h4>
								{/* <p>It was during this challenging time that Dr. Abdul Kalam, a visionary leader, and renowned figure, stepped in to provide crucial support...</p> */}
								<a class="border-link mt-top-2" target="_blank" href="https://www.youtube.com/watch?v=KBkyj-qMO_k">Watch</a>
							</div>
							</div>
						</div>
					</div> 
					<div class="blog-box load-box">
						<div class="card">
							<img src="./assets/images/blog/y11.jpg" alt="Media Img" class="w-100" />
							<div class="container">
							 <div class="blog-title mt-4">
								<h4><a target="_blank" href="https://www.youtube.com/watch?v=475dsu6ZfGo">First Ever Indian Made Social Media Application Pixalive</a> </h4>
								{/* <p>It was during this challenging time that Dr. Abdul Kalam, a visionary leader, and renowned figure, stepped in to provide crucial support...</p> */}
								<a class="border-link mt-top-2" target="_blank" href="https://www.youtube.com/watch?v=475dsu6ZfGo">Watch</a>
							</div>
							</div>
						</div>
					</div> 
					<div class="blog-box load-box">
						<div class="card">
							<img src="./assets/images/blog/y2.jpg" alt="Media Img" class="w-100" />
							<div class="container">
							 <div class="blog-title mt-4">
								<h4><a target="_blank" href="https://www.youtube.com/watch?v=9YJIxKGCqLc">Pixalive - India's First Ever Social Media App | Launch of Pixalive App | PaperPlane</a> </h4>
								{/* <p>It was during this challenging time that Dr. Abdul Kalam, a visionary leader, and renowned figure, stepped in to provide crucial support...</p> */}
								<a class="border-link mt-top-2" target="_blank" href="https://www.youtube.com/watch?v=9YJIxKGCqLc">Watch</a>
							</div>
							</div>
						</div>
					</div>
					<div class="blog-box load-box">
						<div class="card">
							<img src="./assets/images/blog/y3.jpg" alt="Media Img" class="w-100" />
							<div class="container">
							 <div class="blog-title mt-4">
								<h4><a target="_blank" href="https://www.youtube.com/watch?v=jECyj7AedmE">Actress Pranitha & Tik Tok Pranika Graced Pixalive 1st year Anniversary Celebration</a> </h4>
								{/* <p>It was during this challenging time that Dr. Abdul Kalam, a visionary leader, and renowned figure, stepped in to provide crucial support...</p> */}
								<a class="border-link mt-top-2" target="_blank" href="https://www.youtube.com/watch?v=jECyj7AedmE">Watch</a>
							</div>
							</div>
						</div>
					</div>
					<div class="blog-box load-box">
						<div class="card">
							<img src="./assets/images/blog/y4.jpg" alt="Media Img" class="w-100" />
							<div class="container">
							 <div class="blog-title mt-4">
								<h4><a target="_blank" href="https://www.youtube.com/watch?v=eZMTAv2QkpI">Actress Pranitha Brand Ambassador For Pixalive Mobile Application | 1st Anniversary Function</a> </h4>
								{/* <p>It was during this challenging time that Dr. Abdul Kalam, a visionary leader, and renowned figure, stepped in to provide crucial support...</p> */}
								<a class="border-link mt-top-2" target="_blank" href="https://www.youtube.com/watch?v=eZMTAv2QkpI">Watch</a>
							</div>
							</div>
						</div>
					</div>
					{/* <div class="blog-box load-box">
						<div class="card">
							<img src="./assets/images/blog/blog-1.jpg" alt="Media Img" class="w-100" />
							<div class="container">
							 <div class="blog-title mt-4">
								<h4><a target="_blank" href="https://www.youtube.com/watch?v=38tCJexsrX0">Pixalive / India's 1st Social Media App/Anounced Actress Pranitha Subhash as the Brand Ambassador</a> </h4>
								 <a class="border-link" target="_blank" href="https://www.youtube.com/watch?v=38tCJexsrX0">Read More</a>
							</div>
							</div>
						</div>
					</div> */}
				
			
				</div> 
			</div>
		</div>
	</section>
	 


  </> 
  ); 
  } 
  export default HomeBlogs;