
import Header from '../../components/Header'; 
import Footer from '../../components/Footer';

function Products() { 
	return( 
	<>
    
    <div id="body">
      <Header />
      <main class="inner-page" id="main-container">
         <section class="inner-page-banner">
         <div class="card inner-banner-image">
               <div class="card-img-overlay">
                  <div class="breadcrumb">
                     <a href="/">Home</a> <span class="seperator">/</span>
                     <a href="/Products">Products</a> 
                  </div>
                  <h1 class="card-title">Our Products</h1>
               </div>
            </div> 
         </section>

         <section class="mt-5 pt-2">
            <div class="container">							  
                <div class="row text-center">
                    <div class="mb-lg-3 mt-3">
                        <h2 class="big-heading2 text-center mb-3">Our Products</h2>
                        <p class="intro-text">“Connecting Minds, Empowering Businesses: Your Privacy-Centric Network.”</p>
                    </div>
                </div>							  
                <div class="row pb-5 pt-5 understanding-section"> 
                    <div class="col-md-6 pt-lg-2 pr-lg-5">
                        <div class="pr-1">
                            <h2 class="big-heading2 mt-5 mb-4">
                            Pixalive
                            </h2>
                            <p>Pixalive stands as India's pioneering interest-based social media networking application, offering rewards to all users on its platform. It empowers regular users to generate earnings through their daily activities and engagement within the app.</p>
                            <p>Earn Pixalive Rewards by uploading videos, watching content, and inviting friends to join the community. </p>
                            <p>Maximize your benefits by engaging in these activities more frequently! This marks the first instance in India where a social media application values and motivates users through rewards. </p>
                            <h6>Significance & how it differs from Western Social Media</h6>
                            <p><span class="bullet_product"></span>Pixalive is interest based social media application, where you can connect with like-minded people and spend your valuable time on your chosen category and content.</p>
                            <p><span class="bullet_product"></span>Unlike other platform where they value only Celebrities, Influencers and Creators and left users aside. Whereas Pixalive values common users along with others based on their activity in our platform. Also sets a platform to earn money based on their activities in our application.</p>
                            <p><span class="bullet_product"></span>Common users can also become Creators & Influencers on our platform based on their follower’s count. </p>
                        </div>
                    </div>
                    <div class="col-md-6 pt-2 pl-lg-5 df-center">
                        <div class="pl-2 image-link-process">
                            <img src="../assets/images/social.jpg"  alt="" class="w-100 mb-2" />
                            <a href='https://pixalive.me/' target='_blank'>Pixalive Website - <strong>https://pixalive.me/</strong></a>
                            <a href='https://play.google.com/store/search?q=pixalive+app&c=apps&hl=en-IN' target='_blank'>Play Store - <strong>https://play.google.com/store/search?q=pixalive+app&c=apps&hl=en-IN</strong></a>
                            <a href='https://pixalive.me/' target='_blank'>App Store - <strong>https://apps.apple.com/in/app/pixalive/id1491966281</strong></a>
                        </div>
                    </div>
                </div>

                <div class="row pb-5 pt-5 understanding-section">                 
                    <div class="col-md-6 pt-2 pl-lg-5 df-center">
                        <div class="pl-2 image-link-process">
                            <img src="../assets/images/weblive.png"  alt="" class="w-100 mb-2" />
                            <a href='#'>Webalive Website - <strong>Launching Soon</strong></a>
                            <a href='#'>Play Store - <strong>Launching Soon</strong></a>
                            <a href='#'>App Store - <strong>Launching Soon</strong></a>
                        </div>
                    </div>
                    <div class="col-md-6 pt-lg-2 pl-lg-5">
                        <div class="pr-1">
                            <h2 class="big-heading2 mt-5 mb-4">Webalive </h2>
                            <p>Webalive is a business application platform where both business users and common users can post their website links, links to the Play Store and App Store, and list their products in a shopping cart.</p>
                            <p>Users can easily share these links and products with their connections, friends, and networks on social media sites and WhatsApp, and, in return, they can earn money. </p>
                            <p>This not only helps businesses gain traction but also enables them to reach a wider audience and increase lead conversions.</p>
                            <h6>Significance & how it differs from Western Social Media</h6>
                            <p><span class="bullet_product"></span>Many vendors and MSME sectors do not have their own websites; they have been conducting their businesses offline for years. However, they face challenges when it comes to online marketing and expanding their market. They often rely on Western social media platforms, and without a website, they struggle to promote and sell their products. </p>
                            <p><span class="bullet_product"></span>In our platform, we have introduced a cart option that allows them to list their products and conduct business without the need for a website.  </p>
                            <p><span class="bullet_product"></span>If they do want a website for their business, Pixalive can create one through its PCS division at an affordable cost for the vendors. </p>
                        </div>
                        
                    </div>
                </div> 
            </div>
        </section>

         <Footer/>
      </main>
   </div> 

    </> 
    ); 
  } 
  export default Products;