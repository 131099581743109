
import Header from '../../components/Header'; 
import Footer from '../../components/Footer';

function Blog() { 
	return( 
	<>
   <div id="body">
      <Header />
      <main class="inner-page" id="main-container">
         <section class="inner-page-banner">
         <div class="card inner-banner-image">
               <div class="card-img-overlay">
                  <div class="breadcrumb">
                     <a href="/">Home</a> <span class="seperator">/</span>
                     <a href="/AboutUs">Blog</a> 
                  </div>
                  <h1 class="card-title">Our Blog</h1>
               </div>
            </div> 
         </section>

         <div class="paragraph paragraph--type--case-studies-listings paragraph--view-mode--default page-section-default cloud-case-studies-wrap page-title-left page-title-new case-studies-listing-wrap section-hidden-remove">
            <div class="row text-center">
               <div class="mb-lg-3 mt-3">
                  <h2 class="big-heading2 text-center mb-5">
                        Our Blogs
                  </h2>
                  </div>
            </div>
            
            <div>   
               <div class="container">
               <div class="field field--name-field-case-studies-reference field--type-viewsreference field--label-visually_hidden">
                  
                  <div class="field__item">
                     <div class="views-element-container">
                        <div class="case-studies-without-slider view view-all-resources view-id-all_resources view-display-id-case_study_listings  " data-once="ajax-pager">
                           
                           <div class="view-content row">
                              <div class="col-lg-4 col-md-6 col-sm-6 views-row">
                                 <div class="views-field views-field-nothing">
                                    <a href="/TheRiseofPixalive" class="field-content">
                                       <div class="card case-studies-item">
                                          <div class="case-carousel-img" >
                                             <img class="img-fluid"  src="./assets/images/blog/blog-1.jpg" />
                                          </div>
                                          <div class="case-studies-carousel-text">
                                             <div class="case-study-header">
                                                <span class="tag-date"><a href="/TheRiseofPixalive">Social Media</a></span>
                                                <h3 class="card-heading-flex" >The Rise of Pixalive: Pioneering a New Era in India’s Social Media Landscape</h3>
                                             </div>
                                             <div class="case-studies-desc">
                                                <p>It was during this challenging time that Dr. Abdul Kalam, a visionary leader, and renowned figure, stepped in to provide crucial support...</p>
                                             </div>
                                             <a class="link-box" href="/TheRiseofPixalive">
                                                <span>Learn more</span>
                                                <i class="fa fa-long-arrow-right"></i>
                                             </a>
                                          </div>
                                       </div>
                                    </a>
                                 </div>
                              </div>
                              <div class="col-lg-4 col-md-6 col-sm-6 views-row">
                                 <div class="views-field views-field-nothing">
                                    <a href="/PixaliveBengaluruOffice" class="field-content">
                                       <div class="card case-studies-item">
                                          <div class="case-carousel-img" >
                                             <img class="img-fluid"  src="./assets/images/blog/pixalive-pooja.jpg" />
                                          </div>
                                          <div class="case-studies-carousel-text">
                                             <div class="case-study-header">
                                                <span class="tag-date"><a href="/PixaliveBengaluruOffice">Investing</a></span>
                                                <h3 class="card-heading-flex">Pixalive's Bengaluru Office: Transforming Vision Into Reality</h3>
                                             </div>
                                             <div class="case-studies-desc">
                                                <p> In today's fast-paced corporate world, where innovation and collaboration are the keys to success, a new office space can be a game-changer...</p>
                                             </div>
                                             <a class="link-box" href="PixaliveBengaluruOffice">
                                                <span>Learn more</span>
                                                <i class="fa fa-long-arrow-right"></i>
                                             </a>
                                          </div>
                                       </div>
                                    </a>
                                 </div>
                              </div>
                              {/* <div class="col-lg-4 col-md-6 col-sm-6 views-row">
                                 <div class="views-field views-field-nothing">
                                    <a href="/WhatAreTheMostPopularApps" class="field-content">
                                       <div class="card case-studies-item">
                                          <div class="case-carousel-img" >
                                             <img class="img-fluid"  src="./assets/images/blog/blog-5.jpg" />
                                          </div>
                                          <div class="case-studies-carousel-text">
                                             <div class="case-study-header">
                                                <span class="tag-date"><a href="/WhatAreTheMostPopularApps">Social Made</a></span>
                                                <h3 class="card-heading-flex" >What are the 10 most popular apps?</h3>
                                             </div>
                                             <div class="case-studies-desc">
                                                <p>Now that we are into 2020 and must have come across these most popular apps and on the trend...</p>
                                             </div>
                                             <a class="link-box" href="/WhatAreTheMostPopularApps">
                                                <span>Learn more</span>
                                                <i class="fa fa-long-arrow-right"></i>
                                             </a>
                                          </div>
                                       </div>
                                    </a>
                                 </div>
                              </div>
                              <div class="col-lg-4 col-md-6 col-sm-6 views-row">
                                 <div class="views-field views-field-nothing">
                                    <a href="/HeloAppAlternativesinIndiaLikePixalive" class="field-content">
                                       <div class="card case-studies-item">
                                          <div class="case-carousel-img" >
                                             <img class="img-fluid"  src="./assets/images/blog/blog-3.jpg" />
                                          </div>
                                          <div class="case-studies-carousel-text">
                                             <div class="case-study-header">
                                                <span class="tag-date"><a href="/HeloAppAlternativesinIndiaLikePixalive">Social Media</a></span>
                                                <h3 class="card-heading-flex">Helo App Alternatives in India Like Pixalive, Roposo & ShareChat</h3>
                                             </div>
                                             <div class="case-studies-desc">
                                                <p>Pixalive, Roposo, and ShareChat have taken the Chinese Helo app’s market after its ban, along with several other popular Chinese apps...</p>
                                             </div>
                                             <a class="link-box" href="/HeloAppAlternativesinIndiaLikePixalive">
                                                <span>Learn more</span>
                                                <i class="fa fa-long-arrow-right"></i>
                                             </a>
                                          </div>
                                       </div>
                                    </a>
                                 </div>
                              </div> */}
                              <div class="col-lg-4 col-md-6 col-sm-6 views-row">
                                 <div class="views-field views-field-nothing">
                                    <a href="/PixaliveappisNowtrendinginIndia" class="field-content">
                                       <div class="card case-studies-item">
                                          <div class="case-carousel-img" >
                                             <img class="img-fluid"  src="./assets/images/blog/blog-4.jpg" />
                                          </div>
                                          <div class="case-studies-carousel-text">
                                             <div class="case-study-header">
                                                <span class="tag-date"><a href="/PixaliveappisNowtrendinginIndia">Social Media</a></span>
                                                <h3 class="card-heading-flex" >Pixalive app is Now trending in India!</h3>
                                             </div>
                                             <div class="case-studies-desc">
                                                <p>Ever since the ban of the Chinese app by The Ministry of Electronics and Information Technology, India, social media users have been in a turbulence...</p>
                                             </div>
                                             <a class="link-box" href="/PixaliveappisNowtrendinginIndia">
                                                <span>Learn more</span>
                                                <i class="fa fa-long-arrow-right"></i>
                                             </a>
                                          </div>
                                       </div>
                                    </a>
                                 </div>
                              </div> 


                           </div>
                           
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div> 
      </div>





            
      <Footer/>
      </main>
   </div> 

    </> 
    ); 
  } 
  export default Blog;