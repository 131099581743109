import React from 'react';

function  Header () {
  return (
  <>
  
  <header class="intro">
			<div class="topnav inner-page-topnav" id="myTopnav">
				<a href="/" class="logo">
					<img class="logo-white" src="../assets/images/logo.svg" alt="Logo"/>
					<img class="logo-dark" src="../assets/images/logo.svg" alt="Logo"/>
				</a>
				<div class="nav-menu">
					<a href="/">Home</a>
					{/* <a href="/AboutUs">About Us</a> */}
					<a href="/Products">Products</a>
					{/* <div class="dropdown">
						<a class="dropbtn">Products</a>
						<div class="dropdown-content">
							<a href="#">Pixalive</a>
							<a href="#">Webalive</a> 
						</div>
					</div> */}
					<a href="/Investors">Investors</a>
					<a href="/Blog">Blogs</a>
					<a href="ContactUs">Contact Us</a>  
					<a href="javascript:void(0);" class="icon"  >
						<i class="fa fa-bars"></i>
					</a>
				</div>
			</div>
		</header>

  </>
);
}

export default Header;